import React from 'react';

import { PageProps, Link, graphql } from "gatsby"

import Layout from "../../../components/layout"
import SEO from "../../../components/seo"

import './index.scss'
import MercadoLibreLogo from "../../../components/images/mercadolibre"
import SectionNavigate from '../../../components/widgets/sectionNavigate';
import ImageFooted from '../../../components/widgets/imageFooted';
import { IMG_Shopify } from '../../../components/images/docs/shopify';
import { IMG_Mercadolibre } from '../../../components/images/docs/mercadolibre';

type DataProps = {
    site: {
        buildTime: string
    }
}

const ShopifyClavesDefault: React.FC<PageProps<DataProps>> = ({ data, path, location }) => (
    <Layout isEcommerce={true} location={location}>
        <SEO title="FiscalPOP para eCommerce" />
        <div id='ShopifyDocs' className="_DOCS">
            <div className="integrationLogo">
                <MercadoLibreLogo />
            </div>
            <h2>
                Facturación Manual
            </h2>
            <p>
                Desde la sección de <b>Mis pedidos</b> podrás entrar a la vista de detalle de pedido, la cual también funciona para emitir la factura manualmente
            </p>
            <ImageFooted explanation={`Aquí podrás emitir las factura una por una si así lo deséas, así como obtener el Link de facturación que reciben tus clientes.`}>
                <IMG_Mercadolibre file={`facturacionManual`} />
            </ImageFooted>

            <SectionNavigate next={`/ecommerce/mercadolibre/facturacion-reembolso`} previous={`/ecommerce/mercadolibre/link-comprador`}/>
        </div>
    </Layout>
)

export default ShopifyClavesDefault;